<template>
  <fragment>
    <div class="main_page">
      <app-navbar></app-navbar>
      <div class="page">
        <loading :active.sync="isPageLoading" is-full-page></loading>
        <div v-if="!isPageLoading">
          <div class="page-title">
            <h1>Advertisements</h1>
          </div>
          <div class="page-content">
            <div class="alert alert-warning mt-3" role="alert" v-if="!getAdvertisments.length">
              No advertisement groups are available - please contact an administrator to provide these.
            </div>

            <div class="d-flex align-items-center mb-5 bd-highlight" v-if="getAdvertisments.length">
              <div class="custom-control custom-checkbox">
                <input id="enableAdverts" name="enableAdverts" type="checkbox" class="custom-control-input" :checked="getIsAdvertisementsEnabled" @change="toggleAdverts" />
                <label class="custom-control-label" for="enableAdverts">Enable Advertisements</label>
              </div>
              <div class="ml-auto">
                <button type="button" class="btn btn-primary" @click="newAdvertisement">
                  <i class="fas fa-plus"></i>
                  Add New Advertisement
                </button>
              </div>
            </div>

            <div class="mb-5" v-for="(group, index) in getAdvertisments" :key="'adgroup-' + index">
              <h2> {{ group.name }} </h2>

            <div class="alert alert-warning mt-3" role="alert" v-if="!group.advertisements.length">
              No advertisements are available.
            </div>

            <div class="table-responsive mt-3" v-else>
              <table class="table table-hover">
                <thead>
                  <tr>
                    <td scope="col"> Image Name </td>
                    <td scope="col"> Image Alt Tag </td>
                    <td scope="col"> Link </td>
                    <td scope="col"> Link Alt Text </td>
                    <td scope="col" width="150px"> &nbsp; </td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(entry, eIndex) in group.advertisements" :key="'adgroup-' + index + '-advert-' + eIndex">
                    <td> {{ entry.name }} </td>
                    <td> {{ entry.imgAltText }} </td>
                    <td> {{ entry.linkToUrl }} </td>
                    <td> {{ entry.linkTitleText }} </td>
                    <td>
                      <div class="d-flex align-items-start">
                        <div class="pl-2 pr-2 flex-fill">
                          <a href="#" role="button" title="Edit" class="d-flex justify-content-between align-items-center flex-column"
                              @click.prevent="editAdvert(entry.id)">
                            <img src="/img/document.fac8cbfe.svg" alt="" class="mb-1">
                            <span class="small-grey">Edit</span>
                          </a>
                        </div>
                        <div class="pl-2 pr-2 flex-fill">
                          <a href="#" role="button" title="Delete" class="d-flex justify-content-between align-items-center flex-column"
                              @click.prevent="deleteAdvert(group.code, entry.id)">
                            <img src="/img/red_x.d0ed53ac.svg" alt="" class="mb-1">
                            <span class="small-grey">Delete</span>
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="bv-modal-advertisement" size="lg" hide-header hide-footer :no-close-on-backdrop="true">
      <advertisement></advertisement>
    </b-modal>
  </fragment>
</template>

<style scoped>
  h2 {
    font-size: 18px;
  }
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import MessageBox from "@/utils/messagebox";
import Toast from "@/utils/toast";

export default {
  name: "configuration-zones",
  components: {
    'app-navbar': () => import('@/components/app-navbar.vue'),
    'advertisement': () => import('@/views/configuration/components/advertisement.vue')
  },
  data: function() {
    return {
      isPageLoading: true,
      adGroups: []
    };
  },
  computed: {
    ...mapGetters(["getAdvertisments", "getIsAdvertisementsEnabled"])
  },
  methods: {
    toggleAdverts (e) {
      let selection = e.target.checked;
      this.toggleAdvertisementsEnabled(selection).then(() => {
        Toast.success(this, `Advertisements are now ${ selection ? 'enabled' : 'disabled' }`);
      });
    },
    newAdvertisement() {
      this.resetAdvertisement().then(() => {
        this.$bvModal.show("bv-modal-advertisement");
      });
    },
    editAdvert(id) {
      this.fetchAdvertisement(id).then(() => {
        this.$bvModal.show("bv-modal-advertisement");
      });
    },
    async deleteAdvert(code, id) {
      let confirm = await MessageBox.confirm(this, "Remove Advertisement", "Are you sure you want to remove advertisement?");

      if (confirm) {
        this.removeAdvertisement({ code, id }).then(() => {
          Toast.success(this, "Successfully removed advertisement!");
        });
      }
    },
    ...mapActions(["fetchAdvertisement", "fetchAdvertisements", "fetchAdvertisementGroups", "removeAdvertisement", "resetAdvertisement", "toggleAdvertisementsEnabled"])
  },
  mounted: function() {
    Promise.all([
      this.fetchAdvertisements(),
      this.fetchAdvertisementGroups()
    ])
    .then(() => {
      this.isPageLoading = false;
    });
  }
};
</script>
